requirejs(["jquery", "knockout", "knockout.mapping", "sammy", "underscore", "d3", "dagre-d3", "bootstrap"], function ($, ko, kom, sammy, _, d3, dagreD3, bootstrap) {
    /*global Modernizr: true */

    function guid() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    var dataVersion = 6; 
    var app;
    var defaultDataSet = {
        people: [
            { id: "04174A63-D340-499F-B66E-E3F9C161C383", name: "child", gender: "m", alleles: ["", ""] },
            { id: "6D9ABB14-1145-4797-ABAE-353D4A57BC72", name: "mother", gender: "f", alleles: ["", ""] },
            { id: "B0F468BD-CD3C-438A-A563-E37EE936414A", name: "father", gender: "m", alleles: ["", ""] }
        ],
        relations: [
            { personId: "04174A63-D340-499F-B66E-E3F9C161C383", parentId: "6D9ABB14-1145-4797-ABAE-353D4A57BC72", broken: false },
            { personId: "04174A63-D340-499F-B66E-E3F9C161C383", parentId: "B0F468BD-CD3C-438A-A563-E37EE936414A", broken: true }
        ],
        alleles: [
        ],
        mode: "none",
        useSubstructure: false,
        maleMu: 0.0035,
        femaleMu: 0.001,
        silentFrequency: 0.001,
        populationTheta: 0.01
    };
    ko.bindingHandlers.afterRender = {
        update: function (element, valueAccessor, allBindingsAccessor) {
            ko.toJS(allBindingsAccessor());
            setTimeout(function () {
                var value = valueAccessor();
                if (typeof value != 'function' || ko.isObservable(value))
                    throw new Error('run must be used with a function');
                value(element);
            });
        }
    };
    ko.bindingHandlers.modal = {
        init: function (element, valueAccessor) {
            $(element).modal({
                show: false
            });

            var value = valueAccessor();
            if (ko.isObservable(value)) {
                $(element).on("hide.bs.modal", function () {
                    value(false);
                });
            }
            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                $(element).modal("destroy");
            });

        },
        update: function (element, valueAccessor) {
            var value = valueAccessor();
            if (ko.utils.unwrapObservable(value)) {
                $(element).modal("show");
            } else {
                $(element).modal("hide");
            }
        }
    };

    ko.bindingHandlers.numeric = {
        init: function (element, valueAccessor) {
            $(element).on("keydown", function (event) {
                // Allow: backspace, delete, tab, escape, and enter
                if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||
                    // Allow: Ctrl+A
                    (event.keyCode == 65 && event.ctrlKey === true) ||
                    // Allow: . ,
                    (event.keyCode == 188 || event.keyCode == 190 || event.keyCode == 110) ||
                    // Allow: home, end, left, right
                    (event.keyCode >= 35 && event.keyCode <= 39)) {
                    // let it happen, don't do anything
                    return;
                }
                else {
                    // Ensure that it is a number and stop the keypress
                    if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
                        event.preventDefault();
                    }
                }
            });
        }
    };

    function BrutusAjaxApi(computeUrl) {
        var self = this;

        this.loadInitialData = function (doneCallback) {
            var dataSet = null;
            if (window.serializedModel) {
                dataSet = window.serializedModel;
            } else {

                if (parseInt(localStorage.getItem("dataVersion") || "0", 10) === dataVersion) {
                    dataSet = JSON.parse(localStorage.getItem("pedigree"));
                }


                if (dataSet === null) {
                    dataSet = defaultDataSet;
                }
            }

            doneCallback({ success: true, data: dataSet });
        };

        this.loadInitialResults = function(doneCallback) {
            var results = null;
            if (window.results) {
                //results = window.results;
            }
            doneCallback(results);
        };
        this.submitCompute = function (payload, doneCallback) {
            return $.ajax({
                data: JSON.stringify(payload),
                dataType: "json",
                method: "POST",
                url: computeUrl,
                contentType: "application/json; charset=utf-8"
            }).done(function (data, textStatus, jqXHR) {
                if (data.hasError) {
                    doneCallback({ success: false, data: null });
                    console.log(textStatus, data);
                } else {
                    doneCallback({ success: true, data: data });
                    kom.fromJS(data, {}, self);
                }
            }).fail(function (jqXHR, textStatus, errorThrown) {
                doneCallback({ success: false, data: null });
                console.log(textStatus, errorThrown);
            });
        };
    }

    function BrutusBoundApi() {
        this.loadInitialData = function () {

        };
        this.submitCompute = function (payload) {

        };
    }

    function PersonModel(data, viewModel) {
        var self = this;
        this.id = ko.observable();
        this.name = ko.observable();
        this.alleles = ko.observableArray();
        this.ednaId = ko.observable();

        var oldName = null;

        this.children = ko.computed(function () {
            return _.filter(viewModel.relations(), function (r) {
                return r.parent() === self;
            });
        });

        this.parents = ko.computed(function () {
            return _.filter(viewModel.relations(), function (r) {
                return r.person() === self;
            });
        });

        this.canAddParent = ko.computed(function () {
            return self.parents().length < 2;
        });

        this.removeParent = function (e) {
            viewModel.relations.remove(e);
        };

        this.removeChild = function (e) {
            viewModel.relations.remove(e);
        };

        this.canRemove = ko.computed(function () {
            return viewModel.people().length > 1;
        });

        this.remove = function (e) {
            _.each(self.children(), function (r) { viewModel.relations.remove(r); });
            _.each(self.parents(), function (r) { viewModel.relations.remove(r); });
            viewModel.people.remove(self);
        };

        this.addParent = function () {
            app.setLocation("#/vis/person/" + self.id() + "/add/parent");
        };

        this.addChild = function () {
            app.setLocation("#/vis/person/" + self.id() + "/add/child");
        };

        this.addLink = ko.computed(function () {
            return "#/vis/person/" + self.id() + "/add/";
        });

        this.hasAlleles = ko.computed(function () {
            if (self.ednaId()) {
                return true;
            } else {
                return _.some(self.alleles(), function (a) { return a() !== "" && !!a(); });
            }

        });

        kom.fromJS(data, {
            alleles: {
                create: function (o) {
                    return ko.observable(o.data);
                }
            }
        }, self);

        this.addLink = ko.observable(function () {

        });

        //this.name.subscribe(function (oldValue) {
        //    oldName = oldValue;
        //}, null, "beforeChange");

        //this.name.subscribe(function (newValue) {            
        //    app.trigger("updateGraph");
        //});
    }

    function RelationModel(data, viewModel) {
        var self = this;

        this.personId = ko.observable();
        this.parentId = ko.observable();
        this.broken = ko.observable();

        this.person = ko.computed(function () {
            return _.find(viewModel.people(), function (p) { return p.id() === self.personId(); });
        });

        this.parent = ko.computed(function () {
            return _.find(viewModel.people(), function (p) { return p.id() === self.parentId(); });
        });

        kom.fromJS(data, {}, self);
    }

    function AlleleModel(data, viewModel) {
        var self = this;

        this.name = ko.observable();

        this.frequency = ko.observable();

        this.remove = function () {
            viewModel.alleles.remove(self);
        };

        this.add = function () {
            viewModel.alleles.push(new AlleleModel({
                name: self.name(),
                frequency: self.frequency()
            }, viewModel));
            viewModel.newAllele(new AlleleModel(null, viewModel));
        };
        kom.fromJS(data, {}, self);
    }

    function AddRelationModel(viewModel, owner, mode) {
        var self = this;

        this.title = ko.observable(mode.charAt(0).toUpperCase() + mode.substr(1).toLowerCase());
        this.selectedMode = ko.observable(null);

        this.gender = ko.observable();
        this.id = ko.observable(null);
        this.newName = ko.observable();
        this.alleles = ko.observableArray([ko.observable(""), ko.observable("")]);
        this.addEnabled = ko.computed(function () {
            return (!!self.selectedMode()) && (!!self.id() || !!self.newName());
        });

        this.people = ko.computed(function () {
            return viewModel.people();
        });

        this.showPersonPicker = ko.computed(function () {
            return self.selectedMode() === "existing";
        });

        this.showPersonCreator = ko.computed(function () {
            return self.selectedMode() === "new";
        });

        this.add = function () {
            var otherPerson = null;

            if (self.showPersonPicker()) {
                otherPerson = _.find(self.people(), function (p) { return p.id() === self.id(); });
            } else {
                otherPerson = new PersonModel({
                    id: guid(),
                    name: self.newName(),
                    gender: self.gender(),
                    alleles: _.map(self.alleles(), function (a) { return a(); })
                }, viewModel);
                viewModel.people.push(otherPerson);
            }
            var relation;
            if (mode === "child") {
                viewModel.relations.push(new RelationModel({
                    parentId: owner.id(),
                    personId: otherPerson.id(),
                    broken: false
                }, viewModel));
            } else {
                viewModel.relations.push(new RelationModel({
                    personId: owner.id(),
                    parentId: otherPerson.id(),
                    broken: false
                }, viewModel));
            }

            app.setLocation("#/vis/person/" + owner.id());
        };
    }

    function AlleleListModel(viewModel, alleleList) {
        var self = this;

        this.alleles = alleleList;
        this.showAlleles = ko.observable(!!alleleList());
        this.xAllele = ko.computed(function () {
            var value = _.reduce(self.alleles(), function (memo, a) { return memo + parseFloat(a.frequency()); }, 0);
            return new AlleleModel({ name: "X", frequency: 1 - value });
        });
        ko.computed(function () {
            if (!self.alleles()) {
                return;
            }
            var activeAlleles = _.chain(viewModel.people())
                .map(function (p) { return p.alleles(); })
                .flatten()
                .map(function (a) { return a ? a() : ""; })
                .filter(function (a) { return a !== ""; })
                .uniq(false).value();

            var workingList = _(self.alleles()).map(function (a) { return a.name(); });
            var added = _.difference(activeAlleles, workingList);
            var deleted = _.difference(workingList, activeAlleles);

            _.each(added, function (a) {
                self.alleles.push(new AlleleModel({ name: a, frequency: 0 }));
            });

            _.each(deleted, function (a) {
                self.alleles.remove(function (am) { return am.name() === a; });
            });

            self.alleles.sort(function (a1, a2) {
                return a1.name() === a2.name() ? 0 : (parseInt(a1.name(), 10) < parseInt(a2.name()) ? -1 : 1);
            });

        }).extend({ rateLimit: 50 });
    }

    function RaceModel(data, parent) {
        var self = this;
        this.id = ko.observable();
        this.name = ko.observable();

        kom.fromJS(data, {}, self);
    }

    function ViewModel(data, api) {
        var self = this;
        this.ednaId = ko.observable();
        this.caseNo = ko.observable();
        this.people = ko.observableArray();
        this.relations = ko.observableArray();
        this.alleles = ko.observableArray();
        this.races = ko.observableArray();

        this.selectedPerson = ko.observable();

        this.mode = ko.observable();
        this.maleMu = ko.observable();
        this.femaleMu = ko.observable();
        this.silentFrequency = ko.observable();
        this.populationTheta = ko.observable();
        this.useSubstructure = ko.observable();
        this.selectedRace = ko.observable();
        this.h1 = ko.observable(0);
        this.h2 = ko.observable(0);
        this.h1Formula = ko.observable();
        this.h2Formula = ko.observable();
        this.h1Vars = ko.observableArray();
        this.h2Vars = ko.observableArray();
        this.probability = ko.observable(0);
        this.currentView = ko.observable();

        this.results = ko.observable();

        this.alleleList = ko.observable(new AlleleListModel(self, self.alleles));

        this.hasError = ko.observable(false);
        this.processing = ko.observable(false);
        this.showAddRelation = ko.observable(false);
        this.addRelationModel = ko.observable();
        this.loaded = ko.observable(false);

        this.isEdna = ko.computed(function () {
            return self.ednaId !== 0;
        });


        this.graph = ko.computed(function () {
            var g = new dagreD3.graphlib.Graph().setGraph({
                rankdir: "tb"
            });

            _.each(self.people(), function (p) {

                g.setNode(p.id(), {
                    label: p.name(),
                    gender: p.gender(),
                    class: p.hasAlleles() ? "hasalleles" : "noalleles",
                    model: p
                });
            });

            _.each(self.relations(), function (r) {
                g.setEdge(r.parentId(), r.personId(), { style: r.broken() ? "stroke-dasharray: 5, 5;" : "stroke-dasharray: initial;" });
            });
            return g;
        }).extend({ rateLimit: 50 });

        this.selectPerson = function (id) {
            if (self.selectedPerson() && self.selectedPerson().id() === id) return;
            this.selectedPerson(_.find(self.people(), function (p) { return p.id() === id; }));
        };

        this.reset = function () {
            if (confirm("Are you sure you wish to clear the current pedigree?")) {
                localStorage.clear();
                window.location.reload();
            }
        };

        var renderImages = function (model) {

            d3.select("#hiddenSvg").html(d3.select("#diagram").select("g").node().parentNode.innerHTML);
            d3.select("#hiddenSvg").select("g").attr("transform", "");
            var svg = d3.select("#hiddenSvg")
                .attr("version", "1.1")
                .attr("xmlns", "http://www.w3.org/2000/svg")
                .node().parentNode.innerHTML;
            var imgsrc = "data:image/svg+xml;base64," + btoa(svg);

            model.diagramSvg = imgsrc;

        }

        this.updateMathJax = function () {
            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        };

        this.calculate = function () {
            self.hasError(false);
            var model = kom.toJS(self);

            if (Modernizr.localstorage) {
                localStorage.setItem("pedigree", JSON.stringify(model));
                localStorage.setItem("dataVersion", dataVersion);
            }

            self.processing(true);

            renderImages(model);

            api.submitCompute(model, function (result) {
                self.processing(false);
                if (result.success) {
                    kom.fromJS(result.data, {}, self);
                } else {
                    self.results(null);
                    self.hasError(true);
                }
            });
        };

        kom.fromJS(data, {
            people: {
                create: function (opt) {                    
                    return new PersonModel(opt.data, self);
                }
            },
            relations: {
                create: function (opt) {
                    return new RelationModel(opt.data, self);
                }
            },
            alleles: {
                create: function (opt) {
                    return new AlleleModel(opt.data, self);
                }
            },
            races: {
                create: function (opt) {
                    return new RaceModel(opt.data, self);
                }
            }
        }, self);

        api.loadInitialResults(function(results) {
            if (results) {
                kom.fromJS({ results: results }, {}, self);
            }
        });
    }

    var initialScale = 1;

    function doDraw(renderer, g) {
        var parent = $("#chart");

        var width = parent.width();
        var height = parent.height();
        var svg = d3.select("#diagram"),
            inner = d3.select("#diagram g"),
            zoom = d3.behavior.zoom().on("zoom", function () {
                inner.attr("transform", "translate(" + d3.event.translate + ")" +
                    "scale(" + d3.event.scale + ")");
            });

        if (!svg[0][0]) {
            return;
        }

        svg.attr("width", "100%");

        svg.call(zoom);
        d3.select("#diagram g").call(renderer, g);
        zoom
            .translate([(width - g.graph().width * initialScale) / 2, 20])
            .scale(initialScale)
            .event(svg);

        inner.selectAll("g.node").on("click", function (d, i) {
            var node = g.node(d);

            app.setLocation("#/vis/person/" + node.model.id());

        });
    }

    function setupRenderer() {
        var renderer = dagreD3.render();

        var oldDrawNodes = renderer.createNodes();
        renderer.createNodes(function (selection, g, shapes) {
            var svgNodes = oldDrawNodes(selection, g, shapes);
            svgNodes.each(function (u) {
                if (g.node(u).gender === "m") {
                    d3.select(this).select("rect").attr("rx", 0).attr("ry", 0);
                } else {
                    d3.select(this).select("rect").attr("rx", 10).attr("ry", 10);
                }
            });
            return svgNodes;
        });

        return renderer;
    }

    var viewModel;
    app = sammy(function () {
        var self = this;
        var renderer;

        function ensureGraph() {
            if (viewModel.currentView() !== "vis") {
                viewModel.currentView("vis");
                renderer = setupRenderer();
                doDraw(renderer, viewModel.graph());
            }
        }

        this.get("#/", function () {
            this.redirect("#/vis");
        });

        this.get("#/details", function () {
            viewModel.currentView("details");
        });

        this.get("#/formula", function () {
            viewModel.currentView("formula");
        });

        this.get("#/vis", function () {
            viewModel.currentView("vis");
            renderer = setupRenderer();
            doDraw(renderer, viewModel.graph());
        });

        this.get("#/vis/person/:id", function () {
            viewModel.showAddRelation(false);
            ensureGraph();
            var id = this.params["id"];
            viewModel.selectPerson(id);
            var node = viewModel.graph().node(id);
            if (!node) {
                this.redirect("#/vis");
                return;
            }
            d3.select("#diagram g").selectAll("g.node").classed("selected", false);
            d3.select(node.elem).classed("selected", true);
        });

        this.get("#/vis/person/:id/add/:relation", function () {
            ensureGraph();
            var id = this.params["id"];
            var relation = this.params["relation"];
            viewModel.selectPerson(id);
            viewModel.addRelationModel(new AddRelationModel(viewModel, viewModel.selectedPerson(), relation));
            viewModel.showAddRelation(true);
        });

        this.bind("updateGraph", function () {
            if (viewModel.currentView() === "vis") {
                doDraw(renderer, viewModel.graph());
            }
        });

        this.bind("lookup-route", function () {
            viewModel.showAddRelation(false);
        });

        this.bind("run", function () {
            var api = null;
            if (window.brutus) {
                api = new BrutusBoundApi();
            } else {
                api = new BrutusAjaxApi(window.computeUrl);
            }

            api.loadInitialData(function (result) {
                if (!result.success) {
                    return;
                }

                viewModel = new ViewModel(result.data,api);

                viewModel.graph.subscribe(function (newValue) {
                    doDraw(renderer, newValue);
                });
                ko.applyBindings(viewModel);
                viewModel.loaded(true);
            });
        });
    });


    $(function () {
        app.run("#/");
    });
});
define("brutus", function(){});

